
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyCD6D4YJMsUBfIQUgLRagr3bvIdqCn6nvM",
    authDomain: "shyamoly-fincare.firebaseapp.com",
    projectId: "shyamoly-fincare",
    storageBucket: "shyamoly-fincare.appspot.com",
    messagingSenderId: "1069823553901",
    appId: "1:1069823553901:web:74ce90ce11b5325887ea64",
    measurementId: "G-F605N7FXL1"

};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;