import React from 'react'
import Navbar from '../inc/Navbar'
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import shyamolyfincare from "../images/Shymoly.jpg"
function About() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <ScrollToTop smooth top="100" color="maroon" />
      <div className="homeloannadia">
        <div className="album py-1">

          <div className="container mt-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">About us</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className='backc'>
        <br />
        <div className="album py-1">
          <div className="container">
            <h1 style={{ color: "yellow" }}><b>About us</b> SHYAMOLY FINCARE</h1>
            <br />
            </div>
            </div>

            <div className="album py-1">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
            <h5 className='textjustify mb-3'>Whether you're looking to buy your dream Home, need funds to expand your business or what to consolidate your debt, We can tailor a loan just for yoy!</h5>
            <h4 className='textjustify mb-3'> Why choose Shyamoly Fincare ?</h4>
            <h5 className='textjustify mb-4'><svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-check2 me-4" viewBox="0 0 16 16">
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
            </svg> We provide competitive interest rate</h5>
            <h5 className='textjustify mb-4'><svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-check2 me-4" viewBox="0 0 16 16">
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
            </svg>Quick loan approval process</h5>
            <h5 className='textjustify mb-4'><svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-check2 me-4" viewBox="0 0 16 16">
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
            </svg>Wide range of loan options to choose from</h5>
            <h5 className='textjustify mb-4'><svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-check2 me-4" viewBox="0 0 16 16">
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
            </svg> Easy dochumentaion and flexible payment option</h5>
            <h5 className='textjustify mb-5'><svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-check2 me-4" viewBox="0 0 16 16">
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
            </svg> Trusted by thousand of satisfied customer across the country</h5>
            <h5>Unloack your potential and acheive your financial dream with Shyamoly Fincare</h5>
            </div>
            <div className="col-md-6">
            <img src={shyamolyfincare} class="card-img-top" alt="shyamolyfincare"/>

              </div>
            </div>
            </div>
            </div>


        <br />
          
          <div className="album py-1">
          <div className="container">
            <div className="row">
              <div className="col-md-4 mt-3">
                
                <div className="card">
                <div className="card-header"><center>Reliability</center></div>
                  <div className="card-body">
                    <p className="card-text">We are giving our services to the people from the last 2 years. No person has complained about our
                      Honesty and Transperency till today.
                       </p>          
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-3">
                
                <div className="card">
                <div className="card-header"><center>Experience</center></div>
                  <div className="card-body">
                    <p className="card-text">We have 2 years experiencein this job and there are many experience person who can  show you the right way of loan.</p>          
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-3">
                
                <div className="card">
                <div className="card-header"><center>Our Comapany Values</center></div>
                  <div className="card-body">
                    <p className="card-text">Innovative </p>   
                    <br/>       
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br /> 


      </div>
     


    </div>
  )
}

export default About