import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from "../inc/Navbar";
import sbi from "../images/sbi logo.jpg";
import yes from "../images/yes bank.jpg";
import axix from "../images/axis'.jpg";

import baroda from "../images/bank of borada.jpg";
import Hdfc from "../images/HDFC.jpg";
import icici from "../images/icici bank.jpg";
import shiram from "../images/shiram.jpg"

import bankindia from "../images/Bank of india.jpg"
import chola from "../images/chola.jpg"
import piramol from "../images/piramol.jpg";
import jana from "../images/jana finance.jpg";
import prospectus from "../images/prosactus.jpg"

import "./Banklogo.css";


function Silck() {

        const settings = {
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 3
        };

        return (
                <div>
                        <Navbar />

                        <div className="animat">
                                <Slider {...settings}>

                                        <div>

                                                <div className="card mb-4 border-White box ">
                                                        <img src={sbi} className="card-img-top" alt="sbi" />


                                                </div>

                                        </div>

                                        <div>
                                                <div className="card mb-4 border-White box ">
                                                        <img src={yes} className="card-img-top" alt="yes" />


                                                </div>
                                        </div>

                                        <div>
                                                <div className="card mb-4 border-White box ">

                                                        <img src={axix} className="card-img-top" alt="axix" />


                                                </div>
                                        </div>

                                        <div>
                                                <div className="card mb-4 border-White box ">

                                                        <img src={baroda} className="card-img-top" alt="baroda" />


                                                </div>
                                        </div>

                                        <div>
                                                <div className="card mb-4 border-White box ">


                                                        <img src={Hdfc} className="card-img-top" alt="Hdfc" />


                                                </div>
                                        </div>


                                        <div className="card mb-4 border-White box ">

                                                <img src={icici} className="card-img-top" alt="icici" />


                                        </div>

                                        <div className="card mb-4 border-White box ">
                                                <img src={bankindia} className="card-img-top" alt="bankindia"/>
                                        </div>

                                        <div className="card mb-4 border-White box ">
                                                <img src={shiram} className="card-img-top" alt="shiram"/>
                                        </div>
                                        
                                        <div className="card mb-4 border-White box ">
                                                <img src={chola} className="card-img-top" alt="chola"/>
                                        </div>
                                        <div className="card mb-4 border-White box ">
                                                <img src={piramol} className="card-img-top" alt="piramal"/>
                                        </div>


                                        <div className="card mb-4 border-White box ">
                                                <img src={jana} className="card-img-top" alt="jana"/>
                                        </div>

                                        <div className="card mb-4 border-White box ">
                                                <img src={prospectus} className="card-img-top" alt="prospectus"/>
                                        </div>
                                        <div>



                                        </div>


                                </Slider>
                        </div>
                        <br />
                </div>
        )
}



export default Silck