import React from 'react'
import Navbar from '../inc/Navbar'
import businessloan from "../images/business loan banner.jpg";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import business from "../images/Business loan 2.jpg"
import businessloan3 from "../images/BUSINESS LOAN Photo 3.jpg"

function BusinessLoan() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        
        <img src={businessloan} className="d-block w-100" alt="businessloan"/>
        

        
        <ScrollToTop smooth top="100" color="maroon" />

<div className="homeloannadia">
    <div className="album py-1">

        <div className="container mt-3">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Business loan</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<br />

<div className="album py-1">
    <div className="container">
        <div className="row">
            <div className="col-md-9 mt-3">
            <div className="card">

<div className="card-body">
<h5 className="card-title">BUSINESS LOAN</h5>
<p className='texts'>Fueling business growth is our specialty at <b>SHAYAMOLY FINCARE</b>. We offer a diverse range of business loan products designed to support entrepreneurs at every stage of their journey. Whether you're launching a startup, expanding operations, or need working capital, our business loans provide flexible financing options with competitive terms. Our dedicated specialists work closely with you to understand your business goals and tailor solutions that fit your unique needs, empowering you to achieve your ambitions.</p>

<p className="card-text">Additional Benefits:</p>
<p className='mb-1'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{color:"goldenrod"}} fill="currentColor" class="bi bi-check2-all me-2" viewBox="0 0 16 16">
<path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
<path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> <b> Customized Loan Structures : </b> Structured financing solutions that align with your business cash flow and growth projections.
</small></p>
<p className='mb-1'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{color:"goldenrod"}} fill="currentColor" class="bi bi-check2-all me-2" viewBox="0 0 16 16">
<path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
<path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> <strong>Industry Expertise : </strong> Tap into our team's knowledge and experience in various industries to gain valuable insights and support
</small></p>
<p className='mb-4'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"goldenrod"}} className="bi bi-check2-all me-2" viewBox="0 0 16 16">
<path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
<path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> <strong>Access to Networking Opportunities : </strong> Connect with our extensive network of business partners and resources for additional growth opportunities.
</small></p>











<a href="applyloan" className="buttonapply">Apply Now</a>
</div>
</div>
            </div>
            <div className="col-md-3 mt-3">
            <div className="card">
<img src={business} className="card-img-top mb-2" alt="businessloan"/> 
<img src={businessloan3} className="card-img-top mb-2" alt="businessloan"/> 
<div className="card-body">
<p className="card-title"><a href='tel: 9531781475'>Help Line No. +91 9531781475</a></p>

</div>
</div>
            </div>
        </div>
    </div>
</div>

<br/>
<br/>
        <br/>
    </div>
  )
}

export default BusinessLoan