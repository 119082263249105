import React, { useState } from "react";
import { Form, Alert, InputGroup, Button, Card } from "react-bootstrap";
import BookDataCareer from "../services/book.apply";



const AddApply = () => {
    const [title, setTitle] = useState("");
    const [enquiry, setEnquiry] = useState("");
    const [city, setCity] = useState("");

    const [mobile, setMobile] = useState("");
    const [pannumber, setPannumber] = useState("");
    const [status, setStatus] = useState("Available");
    const [flag, setFlag] = useState(true);
    const [massage, setMassage] = useState({ error: false, msg: "" });



    const handleSubmit = async (e) => {
        e.preventDefault();
        setMassage("");
        if (title === "" || mobile === "" || pannumber === "" || city === "" || enquiry === "") {
            setMassage({ error: true, msg: "All fields are mandatory!" });
            return;
        }
        const newBook = {
            title,
            mobile,
            pannumber,

            city,
            enquiry,
            status
        }
        console.log(newBook);

        try {
            await BookDataCareer.addBooks(newBook);
            setMassage({ error: false, msg: "Submited Successfuly!" });
        } catch (err) {
            setMassage({ error: true, msg: err.massage });
        }

        setTitle("");
        setMobile("");
        setPannumber("");

        setCity("");
        setEnquiry("");
    };



    return (
        <>






            {massage?.msg && (<Alert variant={massage?.error ? "danger" : "success"}
                dismissible
                onClose={() => setMassage("")}
            >
                {""}
                {massage?.msg}
            </Alert>
            )}

            <Form onSubmit={handleSubmit} >
                <div className="album py-2">
                    <div className="container">
                        <div className="row ">

                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookTitle">
                                    <InputGroup>
                                        <Form.Control type="text"
                                            placeholder="Name"

                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                        />
                                    </InputGroup>
                                </Form.Group>


                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookMobile">

                                    <Form.Control type="text"
                                        placeholder="Mobile"
                                        value={mobile}
                                        onChange={(e) => setMobile(e.target.value)}
                                    />
                                </Form.Group>
                            </div>

                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookPannumber">
                                    <Form.Control type="text"
                                        placeholder="PAN Number"
                                        value={pannumber}
                                        onChange={(e) => setPannumber(e.target.value)}
                                    />

                                </Form.Group>

                            </div>


                            <div className="col-md-6">

                                <Form.Group className="mb-3" controlId="formBookCity">

                                    <Form.Control type="text"
                                        placeholder="City"
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                    />

                                </Form.Group>
                            </div>


                            <div className="col-md-12">
                                <Form.Group className="mb-3" controlId="formBookEnquiry">


                                <Form.Control as="textarea" row={3}
                    placeholder="Enter your comment here"
                    value={  enquiry }
                    onChange={(e) => setEnquiry(e.target.value)}
                    />

                                </Form.Group>

                            </div>

        
                        </div>
<center>
                        <button className="shamolyfincare" type="Submit">

                            Submit

                        </button>
                        </center>
                    </div>
                </div>

            </Form>






        </>


    )
}
export default AddApply;