import React from 'react'
import Navbar from '../inc/Navbar'
import Isocertificate from "../images/ISO CERTIFICATE.jpeg";


function Certificate() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <div className="album py-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mt-3">
                            <div className="card" >
                                <img src={Isocertificate} className="card-img-top" alt="Isocertificate"/>
                                <center>
                                <div className="card-body">
                                    <h5 className="card-title">ISO CERTIFICATE</h5>

                                </div>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

<br/>
<br/>
        </div>
    )
}

export default Certificate