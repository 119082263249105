import React from 'react'
import Navbar from '../inc/Navbar'
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import loanhome from "../images/house loan.jpeg"

function HomeLoan() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />


            <ScrollToTop smooth top="100" color="maroon" />

            <div className="homeloannadia">
                <div className="album py-1">

                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Home loan</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />

            <div className="album py-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 mt-3">
                        <div className="card">

  <div className="card-body">
    <h5 className="card-title">HOME LOAN</h5>
    <p className="card-text">1. PURPOSE</p>
    <p className='mb-1'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{color:"goldenrod"}} fill="currentColor" class="bi bi-check2-all me-2" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Purchase of new/old residential unit (house/ flat/villa/apartment etc.)
</small></p>
<p className='mb-1'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{color:"goldenrod"}} fill="currentColor" class="bi bi-check2-all me-2" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg>  Construction of residential unit on exiting non agriculture plot.
</small></p>
<p><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"goldenrod"}} className="bi bi-check2-all me-2" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Completion of under construction residential unit.
</small></p>
 <p className="card-text">2. ELIGIBILITY</p>
<p><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"goldenrod"}} className="bi bi-check2-all me-2" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Indian citizens and NRIs
</small></p>
<p><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"goldenrod"}} className="bi bi-check2-all me-2" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Minimum entry age is 18 years and maximum exit age up to 75 years.
</small></p>
<p><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"goldenrod"}} class="bi bi-check2-all me-2" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Individuals may apply singly or jointly with other eligible individuals.
</small></p>


    <a href="applyloan" className="buttonapply">Apply Now</a>
  </div>
</div>
                        </div>
                        <div className="col-md-3 mt-3">
                        <div className="card">
  <img src={loanhome} className="card-img-top" alt="loanhome"/>
  <div className="card-body">
  <p className="card-title"><a href='tel: 9531781475'>Help Line No. +91 9531781475</a></p>

  </div>
</div>
                        </div>
                    </div>
                </div>
            </div>

<br/>
<br/>
        </div>
    )
}

export default HomeLoan